<template>
  <div class="index-header">
    <div class="index-header-components index-header-top"></div>
    <div class="index-header-components index-header-bottom">
      <el-dropdown size="small" @command="select" placement="bottom" trigger="click">
        <!-- 分组1 -->
        <span class="el-dropdown-link">
          格式化工具
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="SimpleJsonViewer" class="dropdown-item-class">Json格式化</el-dropdown-item>
          <el-dropdown-item command="XMLFormatter" class="dropdown-item-class" disabled>XML格式化</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 分组2 -->
      <el-dropdown size="small" @command="select" placement="bottom" trigger="click">
        <span class="el-dropdown-link">
          密码生成/加解密工具
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="RandomPassword" class="dropdown-item-class">随机密码生成</el-dropdown-item>
          <el-dropdown-item command="UUID" class="dropdown-item-class">UUID生成</el-dropdown-item>
          <el-dropdown-item command="Base64Transformer" class="dropdown-item-class">Base64编解码</el-dropdown-item>
          <el-dropdown-item command="HashEncryption" class="dropdown-item-class">MD5/SHA-1/SHA-256加密</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 分组3 -->
      <el-dropdown size="small" @command="select" placement="bottom" trigger="click">
        <span class="el-dropdown-link">
          图片工具
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="ImageCompressor" class="dropdown-item-class" disabled>图片压缩</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexHeader',
  methods: {
    select(component) {
      this.$emit('select', component)
    }
  }
}
</script>

<style scoped>
.index-header {
  width: 100%;
  height: 8rem;
  position: relative;
}

.index-header-components {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
}

.index-header-top {
  height: 65%;
  top: 0;
  left: 0;
  border-bottom: 1px solid lightgray;
}

.index-header-bottom {
  height: 35%;
  bottom: 0;
  right: 0;
  border-bottom: 1px solid lightgray;
  justify-content: center;
  align-items: center;
  display: flex;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  font-size: 1.1rem;
  margin: 20px;
}

.dropdown-item-class {
  text-align: center;
}
</style>