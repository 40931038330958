<template>
  <el-row id="uuid-row">
    <el-descriptions :column="2" border>
      <el-descriptions-item label="选项">
        <el-switch v-model="state.rule.standard" active-text="标准UUID" inactive-text="去除字符 '-'" @change="generate"></el-switch>
      </el-descriptions-item>
      <el-descriptions-item label="生成数量">
        <el-select v-model="state.rule.num" placeholder="请选择生成数量" disabled>
          <el-option value="5" label="5"/>
          <el-option value="10" label="10"/>
          <el-option value="20" label="20"/>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item span="2">
        <el-button type="primary" @click="generate">重新生成</el-button>
      </el-descriptions-item>
      <el-descriptions-item v-if="state.result.length !== 0" label="生成结果" span="2">
        <div v-for="item in state.result" :key="item">
          <el-tooltip class="item" effect="dark" content="复制" placement="top-start">
            <el-button id="copy-btn" icon="el-icon-document-copy" size="mini" plain @click="copy(item)"></el-button>
          </el-tooltip>
          <span>{{ item }}</span>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </el-row>
</template>

<script setup>
// UUID默认一次性生成数量
import {onMounted, reactive} from "vue";

// 生成结果
const state = reactive({
  rule: {
    standard: true,
    num: 10
  },
  result: []
})

// 生成uuid
const generate = () => {
  state.result.length = 0;
  for (let i = 0; i < state.rule.num; i++) {
    let uuid = crypto.randomUUID();
    if (!state.rule.standard) {
      uuid = uuid.replace(/-/g, '');
    }
    state.result.push(uuid);
  }
}

// 复制uuid
const copy = async (text) => {
  await navigator.clipboard.writeText(text);
  this.$message.success("复制成功");
}

// 创建成功后默认生成一次UUID
onMounted(() => {
  generate();
});
</script>

<style scoped>
#uuid-row {
  padding: 1rem 15%;
}

#copy-btn {
  margin: .25rem 1rem;
}
</style>